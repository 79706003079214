<!-- 详情弹窗 -->
<template>
  <a-modal
    title="商品详情"
    :width="580"
    :footer="null"
    :visible="visible"
    @update:visible="updateVisible"
  >
    <a-form
      class="ud-form-detail"
      :label-col="{ sm: { span: 4 }, xs: { span: 6 } }"
      :wrapper-col="{ sm: { span: 20 }, xs: { span: 18 } }"
    >
      <a-row>
        <a-col>
          <a-form-item class="ant-descriptions-title big">基本信息</a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :sm="24" :xs="24">
          <!-- 商品名称 -->
          <a-form-item label="商品名称：">
            <div>
              {{ data.goodsName }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 商品特点 -->
          <a-form-item label="商品特点：">
            <div>
              {{ data.sellingPoint }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 商品分类 -->
          <a-form-item label="商品分类：">
            <div>
              {{ data.categoryName }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 商品主图片 -->
          <a-form-item label="商品主图片：">
            <div>
              <a-image
                alt="example"
                :width="100"
                :height="100"
                style="padding: 0px 10px 10px 0px"
                :src="data.goodsMainPic"
              />
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 商品图片 -->
          <a-form-item label="商品图片：">
            <div>
              <a-image-preview-group>
                <a-image
                  alt="example"
                  :width="100"
                  style="padding: 0px 10px 10px 0px"
                  :src="item"
                  v-for="(item, index) in imgList"
                  :key="index"
                />
              </a-image-preview-group>
            </div>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col>
          <a-form-item class="ant-descriptions-title big"
            >规格/库存</a-form-item
          >
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :sm="24" :xs="24">
          <!-- 商品积分 -->
          <a-form-item label="商品积分：">
            <div>
              {{ data.goodsPrice }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 商品数量 -->
          <a-form-item label="商品数量：">
            <div>
              {{ data.stockNumber }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 商品详情 -->
          <a-form-item label="商品详情：">
            <div>
              <!-- {{ data.goodsContent }} -->
            </div>
            <tinymce-editor :value="data.goodsContent" :init="init" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16"> </a-row>
    </a-form>
  </a-modal>
</template>

<script>
// import * as questionChoiceApi from '@/api/qms/questionChoice'
import TinymceEditor from '@/components/TinymceEditor'
import tinymce from 'tinymce/tinymce'
export default {
  name: 'product-Detail',
  components: {
    TinymceEditor
  },
  emits: ['update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 数据
    data: Object
  },
  data() {
    return {
      imgList: []
    }
  },
  watch: {
    data() {
      if (this.data.goodsPics) {
        this.imgList = []
        const urls = this.data.goodsPics.split(',')
        urls.map((item) => {
          this.imgList.push(item)
        })
      }
    }
  },
  created() {
    tinymce.init({
      selector: '#tinydemo', // 容器，可使用css选择器
      theme: 'advanced',
      readonly: 1
    })
    // tinymce.activeEditor.getBody().setAttribute('contenteditable', false)
  },
  mounted() {
    // var activeEditor = this.tinymce.activeEditor
    // this.tinymce.activeEditor.getBody().setAttribute('contenteditable', false)
  },
  methods: {
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style scoped>
#tinydemo{
  background: chocolate;
}
.answer {
  padding-left: 5%;
}
</style>
